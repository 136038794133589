import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getFutureBoxes } from "../api";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Pouches(){
    const { getAccessTokenSilently } = useAuth0();
    const [pouches, setBoxes] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchToken = async () => {
            const result = await getFutureBoxes(getAccessTokenSilently);
            setBoxes(result.data);
        };   
        fetchToken();    
    }, [getAccessTokenSilently])

    return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Dispatch</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {pouches.sort((a, b) => moment(a.dispatching) - moment(b.dispatching)).map((pouch) => (
            <TableRow
                key={pouch.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {pouch.user?.email}
                </TableCell>
                <TableCell>{pouch.items.map((item) => (<>{item.text}, </>))}</TableCell>
                <TableCell>{moment(pouch.dispatching).fromNow()}</TableCell>
                <TableCell><Button onClick={() => navigate('/pouches/new?addressId=' + pouch.user.addressId + '&start=' + pouch.dispatching)}>Create</Button></TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
    </TableContainer>);
    // const { user, isAuthenticated, isLoading } = useAuth0();
    // if (isLoading) {
    //     return <div>Loading ...</div>;
    // }
    // return (
    //     isAuthenticated && (
    //       <div>
    //         <img src={user.picture} alt={user.name} />
    //         <h2>{user.name}</h2>
    //         <p>{user.email}</p>
    //       </div>
    //     )
    //   );
}