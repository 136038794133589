import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import Auth from './Auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Pouches from './pages/Pouches';
import NewPouch from './pages/NewPouch';
import SoilTest from './pages/SoilTest';
import Ingredients from './pages/Ingredients';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
      domain="lawnplan.eu.auth0.com"
      clientId="71qJan6VXLhUbwWcf7eBRdv3mhN57p03"
      redirectUri={window.location.origin}
      audience="https://lawnplan.api"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <Auth>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App/>}>
              <Route path="/" element={<Dashboard/>}/>
              <Route path="/users" element={<Users/>}/>
              <Route path="/pouches/new" element={<NewPouch/>}/>
              <Route path="/pouches" element={<Pouches/>}/>
              <Route path="/ingredients" element={<Ingredients/>}/>
              <Route path="/soilTest" element={<SoilTest/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </Auth>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
