import { useAuth0 } from '@auth0/auth0-react';
import { Button, Paper, TextField } from '@mui/material';
import * as React from 'react';
import { addSoilTestResults } from '../api';


export default function SoilTest(){
    const { getAccessTokenSilently } = useAuth0();

    const [labId, setLabId] = React.useState("");
    const [pH, setPH] = React.useState("");
    const [p, setP] = React.useState("");
    const [k, setK] = React.useState("");
    const [mg, setMg] = React.useState("");
    const [ca, setCa] = React.useState("");
    const [su, setSu] = React.useState("");
    const [b, setB] = React.useState("");
    const [cu, setCu] = React.useState("");
    const [fe, setFe] = React.useState("");
    const [mn, setMn] = React.useState("");
    const [mo, setMo] = React.useState("");
    const [na, setNa] = React.useState("");
    const [zn, setZn] = React.useState("");
    const [cec, setCec] = React.useState("");

    const [addingState, setAddingState] = React.useState(0);

    const handleAdd = async () => {
        setAddingState(1);
        try {
            await addSoilTestResults(getAccessTokenSilently, 
                {
                    labId: labId,
                    data:[
                        {
                            property: 2,
                            value: pH
                        },
                        {
                            property: 3,
                            value: p
                        },
                        {
                            property: 4,
                            value: k
                        },
                        {
                            property: 6,
                            value: mg
                        },
                        {
                            property: 5,
                            value: ca
                        },
                        {
                            property: 7,
                            value: su
                        },
                        {
                            property: 13,
                            value: b
                        },
                        {
                            property: 12,
                            value: cu
                        },
                        {
                            property: 8,
                            value: fe
                        },
                        {
                            property: 10,
                            value: mn
                        },
                        {
                            property: 14,
                            value: mo
                        },
                        {
                            property: 9,
                            value: na
                        },
                        {
                            property: 11,
                            value: zn
                        },
                        {
                            property: 1,
                            value: cec
                        }
                    ]
                });
            setAddingState(2);
        } catch (error) {
            setAddingState(3);
        }
    }

    return (
        <Paper>
            <TextField label="Lab ID" variant="outlined" value={labId} onChange={(e) => setLabId(e.target.value)} />
            <TextField label="pH" variant="outlined" value={pH} onChange={(e) => setPH(e.target.value)} />
            <TextField label="Phosphorus" variant="outlined" value={p} onChange={(e) => setP(e.target.value)} />
            <TextField label="Potassium" variant="outlined" value={k} onChange={(e) => setK(e.target.value)} />
            <TextField label="Magnesium" variant="outlined" value={mg} onChange={(e) => setMg(e.target.value)} />
            <TextField label="Calcium" variant="outlined" value={ca} onChange={(e) => setCa(e.target.value)} />
            <TextField label="Sulphur" variant="outlined" value={su} onChange={(e) => setSu(e.target.value)} />
            <TextField label="Boron" variant="outlined" value={b} onChange={(e) => setB(e.target.value)} />
            <TextField label="Copper" variant="outlined" value={cu} onChange={(e) => setCu(e.target.value)} />
            <TextField label="Iron" variant="outlined" value={fe} onChange={(e) => setFe(e.target.value)} />
            <TextField label="Manganese" variant="outlined" value={mn} onChange={(e) => setMn(e.target.value)} />
            <TextField label="Molybdenum" variant="outlined" value={mo} onChange={(e) => setMo(e.target.value)} />
            <TextField label="Sodium" variant="outlined" value={na} onChange={(e) => setNa(e.target.value)} />
            <TextField label="Zinc" variant="outlined" value={zn} onChange={(e) => setZn(e.target.value)} />
            <TextField label="CEC" variant="outlined" value={cec} onChange={(e) => setCec(e.target.value)} />

            
            <Button onClick={() => handleAdd()} disabled={addingState === 1}>Add</Button>
                    {addingState === 2 && <p>Success</p>}
                    {addingState === 3 && <p>Error</p>}
        </Paper>
    );
    
}