import axios from "axios";

const url = `${process.env.REACT_APP_FUNCTION_URI}/api`;

const getConfig = async (getAccessTokenSilently) =>{
    const accessToken = await getAccessTokenSilently();
    return {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        }
    }
}

export async function getUsers(getAccessTokenSilently){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/users`, config);
}

export async function getAddress(getAccessTokenSilently, addressId){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/address?id=${addressId}`, config);
}

export async function getFutureBoxes(getAccessTokenSilently){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/futureBoxes`, config);
}

export async function getRequiredIngredients(getAccessTokenSilently){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.get(`${url}/requiredIngredients`, config);
}


export async function calculatePouch(getAccessTokenSilently, addressId, start){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/calculatePouch`, {addressId, start}, config);    
}

export async function addBox(getAccessTokenSilently, addressId, applicationDate, pouches){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/addBox`, {addressId, applicationDate, pouches}, config);
}

export async function addSoilTestResults(getAccessTokenSilently, obj){    
    const config = await getConfig(getAccessTokenSilently);
    return await axios.post(`${url}/results-soil-test`, obj, config);
}
