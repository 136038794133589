import React, {useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from '@mui/material';

export default function Auth({ children }) {
    const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect} = useAuth0();
    const [attemptedRefresh, setAttemptedRefresh] = useState(false);
    if(isLoading)
    {
      return <CircularProgress/>
    }
    if(isAuthenticated)
    {
      return children;
    }
    if(!attemptedRefresh)
    {
      getAccessTokenSilently().finally(() => {setAttemptedRefresh(true)});
      return <CircularProgress/>
    }
  
    loginWithRedirect();
    return <></>  
    
  }