import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getUsers } from "../api";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Users(){
    
    const { getAccessTokenSilently } = useAuth0();
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchToken = async () => {
            const result = await getUsers(getAccessTokenSilently);
            setUsers(result.data);
        };   
        fetchToken();    
    }, [getAccessTokenSilently])

    return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>First</TableCell>
            <TableCell>Last</TableCell>
            <TableCell align="right">Line 1</TableCell>
            <TableCell align="right">Postcode</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {users.map((user) => (
            <TableRow
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {user.email}
                </TableCell>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.firstname}</TableCell>
                <TableCell>{user.lastname}</TableCell>
                <TableCell align="right">{user.line1}</TableCell>
                <TableCell align="right">{user.postCode}</TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
    </TableContainer>);
    // const { user, isAuthenticated, isLoading } = useAuth0();

    // if (isLoading) {
    //     return <div>Loading ...</div>;
    // }
    // return (
    //     isAuthenticated && (
    //       <div>
    //         <img src={user.picture} alt={user.name} />
    //         <h2>{user.name}</h2>
    //         <p>{user.email}</p>
    //       </div>
    //     )
    //   );
}