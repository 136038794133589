import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import {  getRequiredIngredients } from "../api";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Ingredients(){
    const { getAccessTokenSilently } = useAuth0();
    const [ingredients, setIngredients] = useState([]);
    useEffect(() => {
        const fetchToken = async () => {
            const result = await getRequiredIngredients(getAccessTokenSilently);
            setIngredients(result.data);
        };   
        fetchToken();    
    }, [getAccessTokenSilently])

    return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Qty Required</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {ingredients.map((ingredient) => (
            <TableRow
                key={ingredient.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {ingredient.id}
                </TableCell>
                <TableCell>{ingredient.name}</TableCell>
                <TableCell>{ingredient.quantity}</TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
    </TableContainer>);
}