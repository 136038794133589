import { useAuth0 } from '@auth0/auth0-react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';

import { useSearchParams } from "react-router-dom";
import { calculatePouch, addBox, getAddress } from '../api';

export default function NewPouch(){
    const { getAccessTokenSilently } = useAuth0();
    let [searchParams] = useSearchParams();
    const addressId = searchParams.get("addressId");
    const start = searchParams.get("start");

    const [pouches, setPouches] = React.useState([]);
    const [address, setAddress] = React.useState([]);
    const [addingState, setAddingState] = React.useState(0);
    const [boxId, setBoxId] = React.useState("");


    React.useEffect(() => {
        debugger;
        const fetchPouches = async () => {
            const result = await calculatePouch(getAccessTokenSilently, addressId, start);
            setPouches(result.data)
        };   
        const fetchAddress = async () => {
            const result = await getAddress(getAccessTokenSilently, addressId);
            setAddress(result.data);
        }
        fetchPouches();  
        fetchAddress();  
    }, [getAccessTokenSilently, addressId, start])

    const handleAdd = async () => {
        setAddingState(1);
        try {
            const result = await addBox(getAccessTokenSilently, addressId, start, pouches);
            setBoxId(result.data);
            setAddingState(2);
        } catch (error) {
            setAddingState(3);
        }
    }

    return (
        <>
            {
            pouches.map(pouch => (            
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nutrient</TableCell>
                                    <TableCell>Grams Required</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pouch.nutrients.map((nutrient) => (
                                <TableRow
                                    key={nutrient.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {nutrient.name}
                                    </TableCell>
                                    <TableCell>{nutrient.grams.toFixed(2)}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ingredient</TableCell>
                                    <TableCell>Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pouch.ingredients.map((ingredient) => (
                                <TableRow
                                    key={ingredient.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {ingredient.name}
                                    </TableCell>
                                    <TableCell>{ingredient.quantity.toFixed(2)}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <p>NPK {pouch.npk} in {pouch.volume.toFixed(2)} ml</p>
                    <p>58% Slow, 42% Ureic</p>
                    
                </>
            ))}
            <p>{address.firstname}</p>
            <p>{address.lastname}</p>
            <p>{address.line1}</p>
            <p>{address.line2}</p>
            <p>{address.town}</p>
            <p>{address.county}</p>
            <p>{address.postCode}</p>
            
            
            <Button onClick={() => handleAdd()} disabled={addingState === 1}>Add</Button>
                    {addingState === 2 && <p>Success, Box ID {boxId}</p>}
                    {addingState === 3 && <p>Error</p>}
        </>
    );
    
}